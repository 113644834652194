// React imports
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

// MUI Component imports
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

//MUI Icons imports
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const ContactUs = () => {
    const form = useRef(null);

    const sendEmail = (e: any) => {
        e.preventDefault();

        emailjs.sendForm('service_6cazk58', 'template_kofech7', e.form.current, 'N2Eyk-SWfArEO7Jz5')
            .then((result) => {
                console.log(result.text);
                toast("Email sent successfully!")
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <Box className="contact-us-main-box">
            <Box className="contact-us-title-box">
                <Typography
                    variant='h4'
                    sx={{ color: '#ba0101', textDecoration: 'underline', paddingBottom: '20px', fontWeight: '900' }}
                >
                    Contact Us
                </Typography>
                <Typography
                    sx={{ paddingTop: '40px', maxWidth: '1000px', margin: 'auto', p: '10px' }}
                >
                    Contact us if you have any questions regarding our memberships and methods to pay and one of our Customer Service representatives will be more than happy to help with your query.</Typography>
            </Box>
            <Box
                className="contact-us-form-box"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        p: 5,
                        width: 'auto',
                        height: 'auto',
                    },
                }}
            >
                <Paper elevation={3} >
                    <Typography>Contact us by filling the form:</Typography>
                    <form ref={form} onSubmit={sendEmail} className="contact-us-form">
                        <label>Name</label>
                        <input type="text" name="user_name" className="contact-us-input"/>
                        <label>Email</label>
                        <input type="email" name="user_email" className="contact-us-input"/>
                        <label>Message</label>
                        <textarea name="message" className="contact-us-textarea"/>
                        {/* <input type="submit" value="Send" /> */}
                        <Button type="submit" variant="outlined" color="error" sx={{ marginTop: '50px' }}>Send</Button>
                    </form>
                </Paper>
                <Box className="contact-us-social-media-box">
                    <Typography component='div' variant='h4' mb='10px'>Social Media</Typography>
                    <Typography mb='10px'>You can also connect with us through our official social media platforms:</Typography>
                    <Tooltip
                        title="GoldenTip Facebook"
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            className="hover-color-change"
                        >
                            <FacebookIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="GoldenTip Twitter"
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            className="hover-color-change"
                        >
                            <TwitterIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box >
    );
}

export default ContactUs