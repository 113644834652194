// React imports
import React from 'react';

// React Component imports
import Modal from './Modal';

// MUI Components imports
import { Box, Divider, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

const Pricing = () => {
    
    return (
        <Box className="pricing-main-box">
            <Typography
                variant='h4'
                sx={{ color: '#ba0101', textDecoration: 'underline', paddingBottom: '20px', fontWeight: '900' }}
            >
                Pricing
            </Typography>
            <Typography
                variant='body2'
                // fontWeight='bolder'
                sx={{ paddingTop: '40px', maxWidth: '1000px', margin: 'auto', p: '10px' }}
            >
                GoldenTip offers one standard pricing membership for all users, with full access to all our tips, anywhere and at any time. All you need is to log into your GoldenTip account
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        mt: 8,
                        width: 512,
                        height: 'auto',
                    },
                }}
            >
                <Paper elevation={3}>
                    <Box m={2}>
                        <Typography variant='h6' color='error' fontWeight='bold' pb='10px' pt='20px'>MONTHLY</Typography>
                        <Typography variant='body2' color='error' fontWeight='bold' pb='30px'>Full Access</Typography>
                        <Divider sx={{ marginBottom: '50px' }} />
                        <Typography variant='h3' color='error' pb='10px'>£5.99</Typography>
                        <Typography variant='body2' pb='30px'>per month</Typography>
                        <Divider sx={{ marginBottom: '50px' }} />
                        <Typography my='20px'>£200 Rolling Accumulator</Typography>
                        <Divider sx={{ marginBottom: '10px', width: '200px', marginX: 'auto' }} />
                        <Typography my='20px'>Secure Stripe payment</Typography>
                        <Divider sx={{ marginBottom: '10px', width: '200px', marginX: 'auto' }} />
                        <Typography my='20px'>24/7 Customer Service</Typography>
                        <Divider sx={{ marginBottom: '10px', width: '200px', marginX: 'auto' }} />
                        <Typography my='20px'>Facebook platform customer interations</Typography>
                        <Divider sx={{ marginBottom: '10px', width: '200px', marginX: 'auto' }} />
                        <Typography my='20px'>𝕏 platform customer interations</Typography>
                        <Divider sx={{ marginBottom: '50px' }} />
                        <Modal title='Signup' isSignupFlow={true}/>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default Pricing