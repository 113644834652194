// React imports
import { useEffect, useState } from "react";
import axios from "axios";

// MUI component imports
import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';

// Media files
import logo from '../assets/logo.png';

const TipsPlan = () => {

    const [prices, setPrices] = useState<any>([]);

    useEffect(() => {
        fetchPrices();
    }, [])

    const fetchPrices = async () => {
        const { data: response } = await axios.get('http://localhost:8100/subs/prices');
        setPrices(response.data);
        console.log(prices)
    }

    const createSession = async (priceId: string) => {
        const { data: response } = await axios.post('http://localhost:8100/subs/session', {
            priceId,
        })
        window.location.href = response.url;
    }

    return (
        <Container sx={{ paddingTop: '100px' }}>
            <Typography
                component='h1'
                variant='h3'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    textTransform: 'uppercase',
                    margin: 'auto',
                    fontWeight: 'bolder',
                    borderBottom: '2px solid #ba0101',
                    width: '400px'
                }}
            >
                Tips Plan page
            </Typography>
            {prices.map((price: any) => (
                <Card sx={{ maxWidth: 345, margin: '50px auto' }} key={price.id}>
                    <CardActionArea onClick={() => createSession(price.id)}>
                        <CardMedia
                            component="img"
                            height="240"
                            image={logo}
                            alt="GoldenTip Logo"
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                sx={{ fontWeight: '500' }}
                            >
                                GoldenTip Monthly plan
                            </Typography>
                            <Typography
                                gutterBottom
                                variant="body2"
                                color="#ba0101"
                            >
                                {price.unit_amount / 100} {price.currency.toUpperCase()} / monthly {price.type} {price.object}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                gutterBottom
                            >
                                Your GoldenTip Monthly plan provides you access to all our
                                winning tips for a full month, with an automatic recurring price plan
                                billed every month.
                            </Typography>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => createSession(price.id)}
                            >
                                {/* {title} */}
                                Buy Now
                            </Button>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </Container>
    )
}

export default TipsPlan;