// React imports
import * as React from 'react';
import { useContext } from 'react';

// React Component imports
import Modal from './Modal';
import { UserContext } from '../context';
import { Link, useNavigate } from 'react-router-dom';

// MUI imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

// MUI Icons imports
// import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PersonIcon from '@mui/icons-material/Person';

export default function MenuBar() {
    const [state, setState] = useContext(UserContext);

    const navigate = useNavigate();

    const handleLogout = () => {
        setState({ data: null, loading: false, error: null });
        localStorage.removeItem('token');
        navigate('/');
    }

    return (
        <Box sx={{ flexGrow: 1 }} className="menu-bar">
            <AppBar position="fixed" sx={{ backgroundColor: '#252424' }}>
                <Toolbar sx={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Tooltip
                            title="Email us"
                        >
                            <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                className="hover-color-change"
                            >
                                <EmailIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="GoldenTip Facebook"
                        >
                            <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                className="hover-color-change"
                            >
                                <FacebookIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="GoldenTip Twitter"
                        >
                            <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                className="hover-color-change"
                            >
                                <TwitterIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Link to='/'>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1, color: '#ba0101', fontWeight: '700', marginRight: '10px' }}
                            >
                                GoldenTip
                            </Typography>
                        </Link>
                    </Box>

                    {state.data ?
                        <Box sx={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Link to='/tips'>
                                <IconButton
                                    size="medium"
                                    sx={{ color: '#fff', marginRight: '5px' }}
                                    className="hover-color-change"
                                >
                                    <PersonIcon fontSize="inherit" />
                                </IconButton>
                            </Link>
                            <Link to='/'>
                                <Button
                                    onClick={handleLogout}
                                    variant="outlined"
                                    color="error"
                                    size='small'
                                >
                                    Logout
                                </Button>
                            </Link>
                        </Box> :
                        <Modal title='Login' isSignupFlow={false} />
                    }
                </Toolbar>
            </AppBar>
        </Box>
    );
}