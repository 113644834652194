// React imports
import React from 'react';

// MUI Components imports
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

// MUI Icons imports
import KeyIcon from '@mui/icons-material/Key';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArticleIcon from '@mui/icons-material/Article';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

const Membership = () => {
    return (
        <Box className="membership-main-box">
            <Typography
                variant='h4'
                sx={{ color: '#ba0101', textDecoration: 'underline', paddingBottom: '20px', fontWeight: '900' }}
            >
                Membership
            </Typography>
            <Typography
                variant='body2'
                // fontWeight='bolder'
                sx={{ paddingTop: '40px', maxWidth: '1000px', margin: 'auto', p: '10px' }}
            >
                Our monthly membership has proven to be cheapest and most efficient on the current market. This is very easy to set up and give you access to all our tips, so you can build a monthly profit and start generating passive income.
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        mt: 8,
                        width: 256,
                        height: 256,
                    },
                }}
            >
                <Paper elevation={3}>
                    <Box m={2}>
                        <KeyIcon fontSize='large' color='error' />
                        <Typography mt='40px'>Chose our monthly membership and gain full access to all our tips 24/7 for a full month</Typography>
                    </Box>
                </Paper>
                <Paper elevation={3} >
                    <Box m={2}>
                        <AccountBalanceWalletIcon fontSize='large' color='error' />
                        <Typography mt='40px'>Our memberships cost pennies per day, but don’t worry our winning tips will cover that</Typography>
                    </Box>
                </Paper>
                <Paper elevation={3} >
                    <Box m={2}>
                        <ArticleIcon fontSize='large' color='error' />
                        <Typography mt='40px'>You can pause, cancel, resume or delete your membership whenever you want</Typography>
                    </Box>
                </Paper>
                <Paper elevation={3} >
                    <Box m={2}>
                        <LeaderboardIcon fontSize='large' color='error' />
                        <Typography mt='40px'>We provide transparent and daily updated statistics of all our markets for all website visitors</Typography>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default Membership