// React imports
import React from 'react';

// React Component imports
import Modal from './Modal';

// MUI Components imports
import { Box, Typography } from '@mui/material';

// MUI Icon imports
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

const Specialised = () => {
    return (
        <Box className="specialised-main-box">
            <Typography
                variant='h4'
                sx={{ color: '#ba0101', textDecoration: 'underline', paddingBottom: '20px', fontWeight: '900' }}
            >
                GoldenTip
            </Typography>
            <Typography
                variant='h3'
                fontWeight='bolder'
            >
                Specialised in football tips
            </Typography>
            <Typography
                variant='body2'
                // fontWeight='bolder'
                sx={{ paddingTop: '40px', maxWidth: '1000px', margin: 'auto', p: '10px' }}
            >
                We are a great team of professional football analysts, who provide expert tips from around the world of football.
                Our experience along the years has motivated us to create this platform so that we can bring regular extra cash into your pockets.
                GoldenTip brings you daily winning football tips from around the world. We name the tip, you win the cash!
            </Typography>
        </Box>
    )
}

export default Specialised