// React imoprts
import React from 'react';
// import UEFAvideo from '/Users/dorinstanciu/git/goldentip/goldentip-client/src/assets/UEFA_Champions_League_Intro.mp4';

// MUI Components imports
import { Typography } from '@mui/material';

const HeroSection = () => {
    const UEFAvideo = require('../assets/UEFA_Champions_League_Intro.mp4')

    return (
        <Typography component='div' className="hero-section">
            <video className="video-bg" autoPlay muted loop>
                <source src={UEFAvideo} type="video/mp4" />
            </video>
        </Typography>
    )
}

export default HeroSection