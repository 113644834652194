// React imports
import { useEffect, useState } from "react";
import axios from "axios";

// MUI imports
import { Divider, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// MUI icons
import PersonIcon from '@mui/icons-material/Person';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface Tip {
    id: string;
    tip_number: string;
    match_one: string;
    match_two: string;
    amount: string;
    date: string;
    status: string;
    access: string;
}

const Tips = () => {
    const [tips, setTips] = useState<Tip[]>([]);
    const [isLoading, setIsLoading] = useState<any>(true)

    useEffect(() => {
        fetchTips();
    }, [])

    const fetchTips = async () => {
        setIsLoading(true)
        const { data: response } = await axios.get('http://localhost:8100/tips')
        setTips(response);
        setIsLoading(false)
    }

    return (
        <Container sx={{ paddingTop: '100px' }}>
            <Typography component='h1' variant="h3" sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', textTransform: 'uppercase', fontWeight: '900' }}>Tips page</Typography>
            <Divider>
                <Chip label="Account details" size="small" />
            </Divider>

            {tips.length ?
                <List
                    dense
                    subheader={
                        <ListSubheader component="div" disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                            <Box>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar sx={{ width: 24, height: 24, backgroundColor: 'green' }}>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </ListItem>
                            </Box>
                            <Box>
                                <ListItem>
                                    <ListItemText>Your account status type: active </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Your Subscription type: Monthly subscription </ListItemText>
                                </ListItem>
                            </Box>
                        </ListSubheader>
                    }
                ></List>

                :

                <List
                    dense
                    subheader={
                        <ListSubheader component="div" disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                            <Box>
                                <ListItem disableGutters>
                                    <ListItemAvatar>
                                        <Avatar sx={{ width: 24, height: 24, backgroundColor: 'green' }}>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </ListItem>
                            </Box>
                            <Box>
                                <ListItem>
                                    <ListItemText>Your account status type: active </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Your Subscription type: No subscription </ListItemText>
                                </ListItem>
                            </Box>
                        </ListSubheader>
                    }
                ></List>
            }

            <Divider>
                <Chip label="Tips" size="small" />
            </Divider>

            {tips.length ?
                tips.map((tip, index) => {
                    return (
                        <Grid container spacing={2} key={index}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        display: 'grid',
                                        gridTemplateColumns: { md: '1fr 1fr' },
                                        gap: 1,
                                    }}
                                >
                                    <List dense>
                                        <Paper elevation={3} sx={{ padding: '30px', display: 'flex', alignItems: 'center' }}>
                                            <Box>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ width: 24, height: 24, backgroundColor: (tip.status === 'Won' ? 'green' : 'red') }}>
                                                            {tip.status === 'Won' ? <DoneIcon /> : <CloseIcon />}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                </ListItem>
                                            </Box>
                                            <Box>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={tip.tip_number}
                                                        sx={{ color: '#ba0101', textTransform: 'uppercase', borderBottom: '1px solid #ba0101' }}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={tip.match_one}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={tip.match_two}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        secondary={tip.amount}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={'Date: ' + tip.date}
                                                        sx={{ color: '#000' }}
                                                    />
                                                </ListItem>
                                            </Box>
                                        </Paper>
                                    </List>

                                </Box>
                            </Grid>
                        </Grid>
                    )
                })
                :
                (
                    isLoading ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                            <CircularProgress color="success" />
                        </Box> :
                        <Box sx={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '50px auto' }}>
                            <Typography sx={{ width: '250px', marginBottom: '20px', padding: '10px 40px', backgroundColor: '#ba010112' }}>You DO NOT have a monthly plan</Typography>
                            <Button
                                variant="outlined"
                                color="error"
                                component={Link}
                                to="/tips-plan"
                            >
                                Buy A Plan
                            </Button>
                        </Box>
                )
            }
        </Container>
    )
}

export default Tips;