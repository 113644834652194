// React imports
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CSS imports
import './App.css';

// React component imports
import MenuBar from './components/MenuBar';

// React page imports
import LandingPage from './pages/LandingPage';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Tips from './pages/Tips';
import TipsPlan from './pages/TipsPlan';

function App() {
    return (
        <BrowserRouter>
            <MenuBar />
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/tips' element={<ProtectedRoute />}>
                    <Route path='/tips' element={<Tips />} />
                </Route>
                <Route path='/tips-plan' element={<ProtectedRoute />}>
                    <Route path='/tips-plan' element={<TipsPlan />} />
                </Route>
            </Routes>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                theme="light"
            />
        </BrowserRouter>
    );
}

export default App;
