// React imports
import * as React from 'react';

// MUI imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// MUI Icons imports
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <Box sx={{ flexGrow: 1 }} className="footer-main-box">
            <AppBar position="static" sx={{ backgroundColor: '#252424' }}>
                <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Tooltip
                            title="Email us"
                        >
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                className="hover-color-change"
                            >
                                <EmailIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="GoldenTip Facebook"
                        >
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                className="hover-color-change"
                            >
                                <FacebookIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="GoldenTip Twitter"
                        >
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                className="hover-color-change"
                            >
                                <TwitterIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography
                            component="div"
                            sx={{ flexGrow: 1, fontWeight: '700', fontSize: '12px' }}
                        >
                            © 2023 GoldenTip. All rights reserved – Developed by  <Typography component='span' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>
                                <Typography component='a' href='https://www.standwebsolutions.com' target='_blank' sx={{ color: '#ba0101', fontSize: '12px', textDecoration: 'none' }}>STAND Web Solutions</Typography>
                            </Typography>
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Footer