import HeroSection from "../components/HeroSection";
import Specialised from '../components/Specialised';
import Membership from '../components/Membership';
import Pricing from '../components/Pricing';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

const LandingPage = () => {
    return  (
        <div>
            <HeroSection />
            <Specialised />
            <Membership />
            <Pricing />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default LandingPage;