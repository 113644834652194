// React imports
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context";

// MUI Component imports
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const ProtectedRoute = () => {
    const [state] = useContext(UserContext)

    if (state.loading) {
        return (
            <Box sx={{ display: 'flex', marginTop: '80px' }}>
                <CircularProgress />
            </Box>
        )
    }

    return state.data ? <Outlet /> : <Navigate to='/' />
}