// React imports
import React from 'react';
import { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context';

// MUI Component imports
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

// MUI Icons component
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

interface ModalProps {
    title: string,
    isSignupFlow: boolean
}

const Modal = ({ title, isSignupFlow }: ModalProps) => {

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const [state, setState] = useContext(UserContext);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = async () => {
        let response;
        if (isSignupFlow) {
            const { data: signUpData } = await axios.post("http://localhost:8100/auth/signup", {
                email,
                password
            });
            response = signUpData;
        } else {
            const { data: logInData } = await axios.post("http://localhost:8100/auth/login", {
                email,
                password
            });
            response = logInData;
        }

        if (response.errors.length) {
            return setErrorMessage(response.errors[0].msg);
        }
        setState({
            data: {
                id: response.data.user.id,
                email: response.data.user.email,
                customerStripeId: response.data.user.customerStripeId,
            },
            loading: false,
            error: null,
        })
        localStorage.setItem('token', response.data.token);
        axios.defaults.headers.common[
            "authorization"
        ] = `Bearer ${response.data.token}`;
        navigate('/tips');
        handleClose();
    }

    return (
        <div>
            <Button
                onClick={handleOpen}
                variant="outlined"
                color="error"
            >
                {title}
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Input you details to access you GoldenTip account and see our tips</DialogContentText>
                    <Box sx={{ margin: '20px 0' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '20px' }}>
                            <Typography variant='body2' sx={{ marginRight: '10px' }}>Email: </Typography>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="username"
                                type="email"
                                variant="standard"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '20px' }}>
                            <Typography component='div' variant='body2' sx={{ marginRight: '10px' }}>Password: </Typography>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="password"
                                type="password"
                                variant="standard"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '20px' }}>
                            {errorMessage &&
                                <Typography sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    color: 'red',
                                    border: '0px solid red',
                                    padding: '5px',
                                    backgroundColor: '#FFDDDD47',
                                    width: '100%'
                                }}>
                                    <ReportGmailerrorredIcon sx={{ paddingRight: '10px' }} />
                                    {errorMessage}
                                </Typography>
                            }
                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ margin: '10px 0' }}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="error"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClick}
                        variant="outlined"
                        color="error"
                    >
                        {title}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default Modal